body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-column {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.App-top-ad {
}

.App-bottom-ad {
}

.App-link {
  color: #61dafb;
}

input {
  font-family: monospace;
  text-align: center;
}

.fixed-width-button {
  width: 6em;
}

.row-height-normal {
  line-height: normal
}

.text-result-colour {
  color: orangered;
}


/*input[type="date"]::-webkit-calendar-picker-indicator {*/
/*  display: none;*/
/*}*/

